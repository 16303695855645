import React from 'react';
import PropTypes from 'prop-types';

const FasciaIcon = ({ className }) => {
  return (
    <svg
      className={className}
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" stroke="#fff" strokeWidth="1.1713">
        <path d="m5.71 7.577 8.805 3.472v8.916" />
        <path d="m3.05 4.035c.152.041 17.9 7.73 17.9 7.73v2.012h-6.284" />
      </g>
    </svg>
  );
};

FasciaIcon.propTypes = {
  className: PropTypes.string,
};

export default FasciaIcon;
