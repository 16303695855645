import React from 'react';
import PropTypes from 'prop-types';

const DownpipesIcon = ({ className }) => {
  return (
    <svg
      className={className}
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="nonzero"
        d="M18.835 4.286h-5.042a.646.646 0 0 0-.1 1.282v.015h.633v4.494h-.648V9.4a.646.646 0 0 0-.648-.648.646.646 0 0 0-.648.648v5.042c0 .36.288.648.648.648a.65.65 0 0 0 .634-.519h.014v-.648h2.78c.95 0 1.714-.763 1.714-1.714V5.583h.634c.36 0 .648-.288.648-.648 0-.36-.259-.649-.619-.649zm-1.729 7.923a.64.64 0 0 1-.633.634h-2.78v-1.686h1.728V5.583h1.685v6.626zM10.826 8.752a.646.646 0 0 0-.648.648v.663h-2.55a1.93 1.93 0 0 0-1.93 1.93v6.424h-.547a.658.658 0 0 0-.605.648c0 .36.288.649.648.649h5.041c.36 0 .649-.288.649-.649a.646.646 0 0 0-.649-.648H9.53v-4.494h.633v.519c0 .028 0 .057.015.072v.043a.636.636 0 0 0 .634.518.65.65 0 0 0 .633-.518h.015V9.386a.621.621 0 0 0-.634-.634zm-.648 4.09H8.464v5.575H6.778v-6.41c0-.46.375-.85.85-.85h2.564v1.686h-.014z"
      />
    </svg>
  );
};

DownpipesIcon.propTypes = {
  className: PropTypes.string,
};

export default DownpipesIcon;
