import React from 'react';
import PropTypes from 'prop-types';

const SkylightsIcon = ({ className }) => {
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeMiterlimit="10"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="nonzero"
        strokeWidth=".128"
        d="M19.007 13.4v3.738a.46.46 0 0 1-.462.462.46.46 0 0 1-.462-.462V13.4h-4.676v3.738a.46.46 0 0 1-.462.462.46.46 0 0 1-.462-.462V13.4a.453.453 0 0 1-.386-.206l-2.53-3.866-2.53 3.866a.443.443 0 0 1-.154.155v3.801a.46.46 0 0 1-.463.463.46.46 0 0 1-.462-.463V13.4H4.519a.462.462 0 0 1-.385-.72l3.969-6.074a.454.454 0 0 1 .398-.219h7.013c.154 0 .308.077.385.206l3.969 6.075a.465.465 0 0 1-.385.72h-.476v.012zM9.35 7.325l3.365 5.137h5.895l-3.365-5.137H9.35zm-.373 1.13l-.488-.758L5.38 12.45h.989l2.607-3.995zm7.707 3.532h-3.276a.453.453 0 0 1-.385-.205l-2.145-3.276a.465.465 0 0 1 .385-.719h3.276c.154 0 .308.077.385.206l2.145 3.275a.462.462 0 0 1-.385.72zm-.86-.925l-1.53-2.337h-2.157l1.528 2.337h2.158z"
      />
    </svg>
  );
};

SkylightsIcon.propTypes = {
  className: PropTypes.string,
};

export default SkylightsIcon;
