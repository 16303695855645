import React from 'react';
import PropTypes from 'prop-types';

const GuttersIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="100"
    >
      <path
        d="M20.122 7.819a.363.363 0 0 0-.36.36v1.374h-2.027a.363.363 0 0 0-.36.36c0 1.838-1.82 3.332-4.07 3.332h-2.644c-2.233-.017-4.07-1.511-4.07-3.331a.363.363 0 0 0-.36-.361H4.203V8.179a.349.349 0 0 0-.343-.36.363.363 0 0 0-.361.36v7.642c0 .189.155.36.36.36h16.28c.188 0 .36-.154.36-.36V8.179a.378.378 0 0 0-.378-.36zM4.204 15.46v-5.22h1.734c.206 2.06 2.267 3.692 4.757 3.692h2.644c2.49 0 4.534-1.632 4.757-3.692h1.717v5.22H4.204z"
        fill="none"
        stroke="#fff"
        strokeWidth=".4"
      />
      <path
        d="M20.122 7.819a.363.363 0 0 0-.36.36v1.374h-2.027a.363.363 0 0 0-.36.36c0 1.838-1.82 3.332-4.07 3.332h-2.644c-2.233-.017-4.07-1.511-4.07-3.331a.363.363 0 0 0-.36-.361H4.203V8.179a.349.349 0 0 0-.343-.36.363.363 0 0 0-.361.36v7.642c0 .189.155.36.36.36h16.28c.188 0 .36-.154.36-.36V8.179a.378.378 0 0 0-.378-.36zM4.204 15.46v-5.22h1.734c.206 2.06 2.267 3.692 4.757 3.692h2.644c2.49 0 4.534-1.632 4.757-3.692h1.717v5.22H4.204z"
        fillRule="nonzero"
      />
    </svg>
  );
};

GuttersIcon.propTypes = {
  className: PropTypes.string,
};

export default GuttersIcon;
