import React from 'react';
import PropTypes from 'prop-types';

const NewRoofsIcon = ({ className }) => {
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="nonzero"
        d="M19.263 15.788a.713.713 0 0 1-.43-.149l-3.22-2.725a.677.677 0 0 1-.232-.512V9.676h-.66v1.09a.664.664 0 1 1-1.107.496l-1.668-1.52-6.806 5.88c-.28.232-.694.215-.925-.065-.231-.281-.215-.694.066-.925l7.252-6.26a.657.657 0 0 1 .875.016l1.008.908v-.264c0-.364.297-.66.66-.66h1.983c.363 0 .66.296.66.66v3.072l2.99 2.528c.281.23.314.644.083.925-.165.148-.347.23-.529.23z"
      />
    </svg>
  );
};

NewRoofsIcon.propTypes = {
  className: PropTypes.string,
};

export default NewRoofsIcon;
